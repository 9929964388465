import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import Logo from "../components/logo"
import MainImg from "../images/Illustration@2x.png"

import "../components/holding.css"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Pata" />
    
    <div className="container-404">
    <div className="logo-404">
      <Logo />
    </div>
      <div>
        <center>

        <h1 className="header-text">Error 404: NOT FOUND</h1>
        <p className="main-text">This page does not exist... Sorry!</p>
        <div className="main-image">
          <img src={MainImg} />
        </div>
        </center>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
